import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-react-intl';
import { connect } from 'react-redux';
import { sendEvent } from '../utils/amplitude';
import TrainingsLayout from '../layouts/Trainings/TrainingsLayout';

const PageEducation = () => {
	const intl = useIntl();
	//

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="robots" content="noindex, nofollow" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-dns-prefetch-control" content="on" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Школа киберспорта | Scope Academy</title>
				<meta name="description" content="Учись, играй и становись лучше в Scope Academy. Новый уровень обучения CS2, лучшие тренеры, призы и многое другое!" />
				<meta property="og:title" content="Школа киберспорта | Scope Academy " />
				<meta property="og:description" content="Учись, играй и становись лучше в Scope Academy. Новый уровень обучения CS2, лучшие тренеры, призы и многое другое!" />
				<link rel="icon" href="/favicon.ico" />
			</Helmet>
			<TrainingsLayout />
		</>
	);
};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(PageEducation);
