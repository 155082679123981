export const analyticsList = [
	{
		title: 'База гранат',
		desc: 'Узнай раскидки всех актуальных карт из соревновательного мап-пула и приноси пользу в каждом раунде',
		img: '/education/analytics-slides/Analytics-1.webp',
		imgSmall: '/education/analytics-slides/Analytics-1-small.webp',
	},
	{
		title: 'Подробная статистика',
		desc: 'Смотри реплеи матчей и анализируй игровые ситуации вместе с тренером, не заходя в CS',
		img: '/education/analytics-slides/Analytics-2.webp',
		imgSmall: '/education/analytics-slides/Analytics-2-small.webp',
	},
	{
		title: 'Тактическая доска',
		desc: 'Разработай тактику на каждый раунд своей игры и удиви соперника знанием всех таймингов на карте',
		img: '/education/analytics-slides/Analytics-4.webp',
		imgSmall: '/education/analytics-slides/Analytics-4-small.webp',
	},
	{
		title: 'Отчёты после тренировок',
		desc: 'Получай отчет от тренера. Так отслеживать свой прогресс будет ещё удобнее',
		img: '/education/analytics-slides/Analytics-3.webp',
		imgSmall: '/education/analytics-slides/Analytics-3-small.webp',
	},
];
