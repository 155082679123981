import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './header.module.scss';

const Header = () => {
	const intl = useIntl();
	return (
		<header className={styles.header}>
			<a href="https://scope.gg/ru/" target="_blank" className={styles.header__logo}>
				<img src="/education/Header-Logo.svg" alt="Scope.gg" />
			</a>
			<div className={styles.header__lang}>
				{/*<span>Язык</span>
				RU
				<img src="/education/Arrow-Lang.svg" alt="Scope.gg" />*/}
			</div>
		</header>
	);
};

export default Header;
