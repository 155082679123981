import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './price.module.scss';

const Price = ({ onClick }) => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<section className={styles.price}>
				<div className={styles.price__wrapper}>
					<div className={styles.price__wrapper__block}>
						<div className={styles.price__wrapper__block__title}>цена за курс</div>
						<div className={styles.price__wrapper__block__oldprice}>
							<span>7250₽</span>
							<div className={styles.price__wrapper__block__sale}>–31%</div>
						</div>
						<div className={styles.price__wrapper__block__price}>5000₽</div>
					</div>
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLScFtq3Nz_zKtv5hAxSHAvNLBBOVHaDDPfbWC7qtIBSLKVUMlQ/viewform?usp=sf_link"
						target="_blank"
						rel="nofollow"
						className={[styles.button, styles.button_dark, styles.price__waitlist].join(' ')}
						onClick={() => {
							mixPanelService.sendEvent({
								event: 'Academy Landing / Click Waitlist Button',
								properties: {},
							});
						}}
					>
						записаться в лист ожидания
					</a>
				</div>
				<div className={styles.price__features}>
					<div className={styles.price__features__block}>
						<div className={styles.price__features__block__tag}>25 часов практики</div>
						<div className={styles.price__features__block__tag}>10 часов теории</div>
						<div className={styles.price__features__block__tag}>4 недели</div>
						<div className={styles.price__features__block__tag}>3 тренировки в неделю</div>
						<div className={styles.price__features__block__tag}>Турнир</div>
						<div className={styles.price__features__block__tag}>Призы</div>
						<div className={styles.price__features__block__tag}>Новые друзья</div>
						<div className={styles.price__features__block__tag}>Интересные домашние задания</div>
					</div>
					<button
						className={styles.button}
						onClick={() => {
							mixPanelService.sendEvent({
								event: 'Academy Landing / Click Buy Button',
								properties: {},
							});
							onClick()
						}}
					>
						купить сейчас со скидкой
					</button>
				</div>
			</section>
		</div>
	);
};

export default Price;
