import React, { useEffect, useState } from 'react';
import * as styles from './successPopup.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';

const SuccessPopup = ({ account, isOpen, onClose }) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);

	useEffect(() => setOpen(isOpen), [isOpen]);

	return (
		<div
			className={[styles.successContainer, open ? styles.open : ''].join(' ')}
			role="dialog"
		>
			<div className={styles.wrapper}>
				<img src="/education/popups/SucessPopup-Man.png" />
				<div className={styles.content}>
					<img src="/education/popups/SucessPopup-Bg.svg" />
					<div className={styles.content__wrapper}>
						<button
							className={styles.btn_close}
							onClick={onClose}
						>
							<img src="/education/popups/SucessPopup-Close.svg" />
						</button>
						<div className={styles.title}>
							Время побеждать!
						</div>
						<div className={styles.desc}>
						Анонсы, расписание и связь с тренером<br />будут ждать тебя в нашем<br />Telegram-канале!
						</div>
						<a
							href={`https://t.me/ScopeAcademyBot?start=${account?.userData?.steamAccountId}`}
							target="_blank"
							rel="nofollow"
							className={styles.btn_tg}
							onClick={() => {
								mixPanelService.sendEvent({
									event: 'Academy Landing / Click Telegram Button',
									properties: { location: 'suc_pay_popup' },
								});
							}}
						>
							<img src="/education/popups/SucessPopup-Tg.svg" />
							присоединиться
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SuccessPopup;
