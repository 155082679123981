export const trainersList = [
	{
		photo: '/education/trainers/Trainer-3.webp',
		bg: 'purple',
		name: 'zonic',
		steam: 'https://steamcommunity.com/id/nyankin/',
		faceit: 'https://www.faceit.com/en/players/nyankin',
		hltv: 'https://www.hltv.org/player/7998/s1mple',
		langs: 'Russian, English',
		timezone: 'GMT +3',
		stats: '2345 hours | 2345 ELO',
		awards: [
			{ place: '1st', title: 'on ENE Cup Tournament' },
			{ place: '2nd', title: 'on ENE Cup Tournament' },
		],
	},
	{
		photo: '/education/trainers/Trainer-2.webp',
		bg: 'green',
		name: 'HOOCH',
		steam: 'https://steamcommunity.com/id/nyankin/',
		faceit: 'https://www.faceit.com/en/players/nyankin',
		hltv: 'https://www.hltv.org/player/7998/s1mple',
		langs: 'Russian, English',
		timezone: 'GMT +3',
		stats: '2345 hours | 2345 ELO',
		awards: [
			{ place: '1st', title: 'on ENE Cup Tournament' },
			{ place: '2nd', title: 'on ENE Cup Tournament' },
		],
	},
	{
		photo: '/education/trainers/Trainer-1.webp',
		bg: 'blue',
		name: 'B1ad3',
		steam: 'https://steamcommunity.com/id/nyankin/',
		faceit: 'https://www.faceit.com/en/players/nyankin',
		hltv: 'https://www.hltv.org/player/7998/s1mple',
		langs: 'Russian, English',
		timezone: 'GMT +3',
		stats: '2345 hours | 2345 ELO',
		awards: [
			{ place: '1st', title: 'on ENE Cup Tournament' },
			{ place: '2nd', title: 'on ENE Cup Tournament' },
		],
	},
];
