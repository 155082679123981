import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './trainer_mock_item.module.scss';

const TrainerMockItem = ({
	bg,
	active,
	isNext,
	offset = 0,
}) => {
	const intl = useIntl();
	return (
		<div
			className={[styles.trainer_mock_item, active ? styles.trainer_mock_item_big : ''].join(' ')}
			style={{ right: isNext ? offset : 0 }}
		>
			{bg === 'purple' ? (
				<img src="/education/trainers/BG-Purple-Mock.svg" className={styles.trainer_mock_item__bg} />
			) : bg === 'green' ? (
				<img src="/education/trainers/BG-Green-Mock.svg" className={[styles.trainer_mock_item__bg, styles.trainer_mock_item__bg_green].join(' ')} />
			) : bg === 'blue' ? (
				<img src="/education/trainers/BG-Blue-Mock.svg" className={styles.trainer_mock_item__bg} />
			) : null}
			{bg === 'purple' ? (
				<img
					src="/education/trainers/BG-Purple-Mock-Tablet.svg"
					className={[styles.trainer_mock_item__bg, styles.trainer_mock_item__bg_tablet, styles.trainer_mock_item__bg_tablet_purple].join(' ')}
				/>
			) : bg === 'green' ? (
				<img
					src="/education/trainers/BG-Green-Mock-Tablet.svg"
					className={[styles.trainer_mock_item__bg, styles.trainer_mock_item__bg_tablet].join(' ')}
				/>
			) : bg === 'blue' ? (
				<img
					src="/education/trainers/BG-Blue-Mock-Tablet.svg"
					className={[styles.trainer_mock_item__bg, styles.trainer_mock_item__bg_tablet].join(' ')}
				/>
			) : null}
			<div className={styles.trainer_mock_item__photo}>
				<img src="/education/trainers/Trainer-Mock.webp" alt="" />
			</div>
			<div className={styles.trainer_mock_item__content}>
				<div className={styles.trainer_mock_item__text}>
					Здесь появится информация насколько крут твой тренер!
				</div>
				<div className={styles.trainer_mock_item__logo}>
					<img src="/education/trainers/Scope-Logo.svg" />
				</div>
			</div>
		</div>
	);
};

export default TrainerMockItem;
