import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './analytics.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import CarouselItem from '../../../atoms/trainings/CarouselItem';
import { analyticsList } from '../../../../constants/trainings/analytics';

SwiperCore.use([Navigation]);

const Analytics = () => {
	const intl = useIntl();
	const [activeSlide, setActiveSlide] = useState(0);
	const [swiperApi, setSwiperApi] = useState({});
	const [width, setWidth] = useState();

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = (event) => {
			setWidth(event.target.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const onSlideChange = (activeIndex) => {
		setActiveSlide(activeIndex);
	};

	return (
		<section className={styles.analytics}>
			<div className={styles.analytics__title}>
				С помощью наших аналитических инструментов твое обучение станет ещё продуктивнее, удобнее и интереснее!
			</div>
			<div className={styles.analytics__wrapper}>
				<div className={styles.analytics__wrapper__content}>
					<div className={styles.analytics__wrapper__content__slider}>
						<Swiper
							slidesPerView={3}
							loop={true}
							spaceBetween={0}
							centeredSlides={true}
							className="slides"
							navigation={{ nextEl: '.btn-next', prevEl: '.btn-prev' }}
							onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
							onSwiper={(swiper) => setSwiperApi(swiper)}
						>
							{analyticsList.map((item, index) => (
								<SwiperSlide key={index} className="slide" width={866}>
									{({ isActive, isNext }) => (
										<>
											{isActive ? (
												<CarouselItem active={true} {...item} offset={width < 1024 ? 0 : '-46px'} />
											) : isNext ? (
												<CarouselItem isNext={true} {...item} offset={width < 1024 ? 0 : '-46px'} />
											) : (
												<CarouselItem {...item} offset={width < 1024 ? 0 : '-46px'} />
											)}
										</>
									)}
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
				<div className={styles.analytics__wrapper__buttons}>
					<button className={[styles.button__circle, 'btn-prev'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 9L12 1V17L2 9Z" fill="white" />
						</svg>
					</button>
					<button className={[styles.button__circle, 'btn-next'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 9L6 1V17L16 9Z" fill="white" />
						</svg>
					</button>
				</div>
			</div>
		</section>
	);
};

export default Analytics;
