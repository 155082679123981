import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './programm.module.scss';
import ProgrammAccordionItem from '../../../atoms/trainings/ProgrammAccordionItem/ProgrammAccordionItem';
import { programmList } from '../../../../constants/trainings/programm';

const Programm = () => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<section className={styles.programm}>
				<div className={styles.programm__wrapper}>
					<div className={styles.programm__title}>Программа курса</div>
					<div className={styles.programm__desc}>
						Часовая нагрузка и наполняемость программы курса могут изменяться в зависимости от тренера и уровня
						индивидуальной подготовки игроков
					</div>
					<div className={styles.programm__features}>
						<div className={styles.tag}>4 недели</div>
						<div className={styles.tag}>25 часов практики</div>
						<div className={styles.tag}>10 часов теории</div>
						<div className={styles.tag}>3 тренировки в неделю</div>
						<div className={styles.tag}>10 человек в группе</div>
					</div>
				</div>
				<div className={styles.programm__list}>
					{programmList.map((item, index) => (
						<ProgrammAccordionItem key={index} blockId={index + 1} {...item} />
					))}
				</div>
			</section>
		</div>
	);
};

export default Programm;
