import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './steps.module.scss';

const Steps = () => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<div className={styles.steps}>
				<div className={styles.steps__block}>
					<img src="/education/Step-1.png" alt="Раскрой свой потенциал" />
					<div className={styles.steps__block__content}>
						<div className={styles.steps__block__content__title}>
							Раскрой свой <br />
							потенциал
						</div>
						<div className={styles.steps__block__content__desc}>
							С помощью наших тренировок улучши свою игру,
							<br />
							научись читать соперника и быстро принимать
							<br />
							эффективные решения прямо в катке!
						</div>
					</div>
					<svg width="1323" height="424" viewBox="0 0 1323 424" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 270.212V6.10352e-05L1323 26.9539V424L0 270.212Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_tablet}
						width="599"
						height="267"
						viewBox="0 0 599 267"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 188.292V0L599 13.7948V267L0 188.292Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_mobile}
						width="348"
						height="504"
						viewBox="0 0 348 504"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0.5 426.381V0L347.5 13.6041V504L0.5 426.381Z" fill="#18193C" />
					</svg>
				</div>
				<div className={styles.steps__block}>
					<div className={styles.steps__block__content}>
						<div className={styles.steps__block__content__title}>
							Почувствуй <br />
							соревновательный <br />
							дух Counter-Strike
						</div>
						<div className={styles.steps__block__content__desc}>
							Научись играть в команде и попробуй настоящий командный <br />
							Counter-Strike: никакого бездумного пуша каждый раунд
						</div>
					</div>
					<img src="/education/Step-2.png" alt="Почувствуй соревновательный дух Counter-Strike" />
					<svg width="1494" height="356" viewBox="0 0 1494 356" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M39.5 355.792L0 0.292175L1369.5 60.7029L1493.5 278.292L39.5 355.792Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_tablet}
						width="677"
						height="243"
						viewBox="0 0 677 243"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M17.9053 243L0 0L620.791 31.0975L677 203.105L17.9053 243Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_mobile}
						width="348"
						height="488"
						viewBox="0 0 348 488"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M9.67744 488L0.5 0L327 24L347.5 433.994L9.67744 488Z" fill="#18193C" />
					</svg>
				</div>
				<div className={styles.steps__block}>
					<img src="/education/Step-3.png" alt="Найди замотивированых тиммейтов" />
					<div className={styles.steps__block__content}>
						<div className={styles.steps__block__content__title}>
							Найди <br />
							замотивированых
							<br /> тиммейтов
						</div>
						<div className={styles.steps__block__content__desc}>
							Никакой токсичности и предвзятости.
							<br />
							Общайся, соревнуйся и учись только с такими же
							<br />
							заряженными игроками, как ты сам!
						</div>
					</div>
					<svg width="1338" height="496" viewBox="0 0 1338 496" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 459.418L57.3146 164.971L1093 6.10352e-05L1338 496L0 459.418Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_tablet}
						width="636"
						height="284"
						viewBox="0 0 636 284"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 272.267L25.9586 91.4812L525.036 0L636 284L0 272.267Z" fill="#18193C" />
					</svg>
					<svg
						className={styles.steps__block__bg_mobile}
						width="348"
						height="506"
						viewBox="0 0 348 506"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0.5 488.136L15.3641 86.6821L323 0L347.5 506L0.5 488.136Z" fill="#18193C" />
					</svg>
				</div>
			</div>
		</div>
	);
};

export default Steps;
