import { axiosInstance } from '../utils/api';

class PaymentService {
	createPayment({ currency, failUrl, successUrl }) {
		return axiosInstance.post('/payment/createPayment', {
			currency,
			failUrl,
			successUrl
		});
	}

	getPaymentStatus({ paymentId }) {
		return axiosInstance.post('/payment/status', {
			paymentId,
		});
	}
}

export const paymentService = new PaymentService();
