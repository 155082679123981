import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './education_item.module.scss';

const EducationItem = ({ title, desc, bg, bgSmall }) => {
	const intl = useIntl();
	return (
		<div className={styles.education_item}>
			<div className={styles.education_item__bg}>
				<img src={bg} />
			</div>
			<div className={styles.education_item__bg_small}>
				<img src={bgSmall} />
			</div>
			<div className={styles.education_item__content}>
				<div className={styles.education_item__title}>{title}</div>
				<div className={styles.education_item__desc}>{desc}</div>
			</div>
		</div>
	);
};

export default EducationItem;
