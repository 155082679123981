import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './education.module.scss';
import { educationList } from '../../../../constants/trainings/education';
import EducationItem from '../../../atoms/trainings/EducationItem';

const Education = () => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<section className={styles.education}>
				<div className={styles.education__title}>Новый уровень обучения CS2</div>
				<div className={styles.education__wrapper}>
					{educationList.map((item, index) => (
						<EducationItem key={index} {...item} />
					))}
				</div>
			</section>
		</div>
	);
};

export default Education;
