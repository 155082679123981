import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './promo.module.scss';

const Promo = ({ onClick }) => {
	const intl = useIntl();
	return (
		<div className={styles.promo}>
			<div className={styles.promo__title}>
				<span>Учись, </span>играй
				<br />
				и становись лучше
				<br />
				<div className={styles.promo__title__academy}>
					<div>в Scope</div>
					<div>Academy</div>
				</div>
			</div>
			<div className={styles.promo__desc}>
				Весь материал основан
				<br /> на тактической подготовке
				<br /> киберспортсменов Тир 1 уровня!
			</div>
			<div className={styles.promo__img}>
				<img src="/education/Promo-Img-New-2.webp" alt="Учись, играй и становись лучше в Scope Academy" />
			</div>
			<div className={styles.promo__action}>
				<button
					className={styles.button}
					onClick={() => {
						mixPanelService.sendEvent({
							event: 'Academy Landing / Click Buy Scroll Button',
							properties: { location: '1st screen' },
						});
						onClick();
					}}
				>
					купить сейчас со скидкой
				</button>
				<div className={styles.sale}>–31%</div>
			</div>
			<div className={styles.promo__disclaimer}>
				Первые результаты появятся уже спустя <span>2 недели</span> тренировок
			</div>
		</div>
	);
};

export default Promo;
