import React, { useEffect, useState } from 'react';
import * as styles from './failPopup.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';

const FailPopup = ({ isOpen, onClick, onClose }) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);

	useEffect(() => setOpen(isOpen), [isOpen]);

	return (
		<div
			className={[styles.failContainer, open ? styles.open : ''].join(' ')}
			role="dialog"
		>
			<div className={styles.wrapper}>
				<img src="/education/popups/FailPopup-Man.png" />
				<div className={styles.content}>
					<img src="/education/popups/SucessPopup-Bg.svg" />
					<div className={styles.content__wrapper}>
						<button
							className={styles.btn_close}
							onClick={onClose}
						>
							<img src="/education/popups/SucessPopup-Close.svg" />
						</button>
						<div className={styles.title}>
							<img src="/education/popups/FailPopup-Warning.svg" />
							Ошибка оплаты
						</div>
						<div className={styles.desc}>
							Сделай эко и попробуй ещё раз
						</div>
						<button
							href="https://discord.gg/SSZYC2sBub"
							target="_blank"
							rel="nofollow"
							className={styles.btn}
							onClick={onClick}
						>
							вернуться к оплате
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FailPopup;
