import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './reviews.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import { reviewsList } from '../../../../constants/trainings/reviews';

SwiperCore.use([Navigation]);

const Reviews = () => {
	const intl = useIntl();

	const [activeSlide, setActiveSlide] = useState(0);
	const [swiperApi, setSwiperApi] = useState({});

	const onSlideChange = (activeIndex) => {
		setActiveSlide(activeIndex);
	};

	return (
		<div className={styles.container}>
			<section className={styles.reviews}>
				<div className={styles.reviews__title}>Мы помогаем достичь результата</div>
				<div className={styles.reviews__block}>
					<div className={styles.reviews__block__photo}>
						<Swiper
							slidesPerView={1}
							loop={true}
							spaceBetween={0}
							className="slides"
							navigation={{ nextEl: '.btn-next-reviews', prevEl: '.btn-prev-reviews' }}
							onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
							onSwiper={(swiper) => setSwiperApi(swiper)}
						>
							{reviewsList.map((item, index) => (
								<SwiperSlide key={index} className="slide">
									<img src={item.photo} alt={item.author} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div className={styles.reviews__block__content}>
						<Swiper
							slidesPerView={1}
							loop={true}
							spaceBetween={0}
							className="slides"
							navigation={{ nextEl: '.btn-next-reviews', prevEl: '.btn-prev-reviews' }}
							onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
							onSwiper={(swiper) => setSwiperApi(swiper)}
						>
							{reviewsList.map((item, index) => (
								<SwiperSlide key={index} className="slide">
									<div className={styles.reviews__block__wrapper}>
										<div className={styles.reviews__block__text}>{item.text}</div>
										<div className={styles.reviews__block__author}>
											<div>{item.author}</div>
											<div className={styles.reviews__block__socials}>
												<a
													className={styles.reviews__block__socials__discord}
													href="#"
													target="_blank"
													rel="nofollow noopener"
												>
													<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M23.2156 7.90238C21.5298 6.59572 19.5069 5.94238 17.3716 5.8335L17.0344 6.16016C18.945 6.59572 20.6307 7.46683 22.2041 8.66461C20.2936 7.68461 18.1583 7.03127 15.9106 6.8135C15.2362 6.70461 14.6743 6.70461 14 6.70461C13.3257 6.70461 12.7638 6.70461 12.0894 6.8135C9.84174 7.03127 7.70642 7.68461 5.79587 8.66461C7.36927 7.46683 9.05505 6.59572 10.9656 6.16016L10.6284 5.8335C8.49312 5.94238 6.47018 6.59572 4.7844 7.90238C2.87385 11.3868 1.86239 15.3068 1.75 19.3357C3.43578 21.0779 5.79587 22.1668 8.26835 22.1668C8.26835 22.1668 9.05505 21.2957 9.61697 20.5335C8.15596 20.2068 6.80734 19.4446 5.90826 18.2468C6.69495 18.6824 7.48165 19.1179 8.26835 19.4446C9.27982 19.8802 10.2913 20.0979 11.3028 20.3157C12.2018 20.4246 13.1009 20.5335 14 20.5335C14.8991 20.5335 15.7982 20.4246 16.6972 20.3157C17.7087 20.0979 18.7202 19.8802 19.7317 19.4446C20.5183 19.1179 21.305 18.6824 22.0917 18.2468C21.1927 19.4446 19.844 20.2068 18.383 20.5335C18.945 21.2957 19.7317 22.1668 19.7317 22.1668C22.2041 22.1668 24.5642 21.0779 26.25 19.3357C26.1376 15.3068 25.1261 11.3868 23.2156 7.90238ZM10.2913 17.3757C9.16743 17.3757 8.15596 16.3957 8.15596 15.1979C8.15596 14.0002 9.16743 13.0202 10.2913 13.0202C11.4151 13.0202 12.4266 14.0002 12.4266 15.1979C12.4266 16.3957 11.4151 17.3757 10.2913 17.3757ZM17.7087 17.3757C16.5849 17.3757 15.5734 16.3957 15.5734 15.1979C15.5734 14.0002 16.5849 13.0202 17.7087 13.0202C18.8326 13.0202 19.844 14.0002 19.844 15.1979C19.844 16.3957 18.8326 17.3757 17.7087 17.3757Z" fill="#93A8BF"/>
													</svg>
													freaksarenotdead
												</a>
												<a
													className={styles.reviews__block__socials__faceit}
													href="#"
													target="_blank"
													rel="nofollow noopener"
												>
													<img src="/education/reviews/Reviews-Social-Faceit.svg" alt="faceit" />
												</a>
												<a
													className={styles.reviews__block__socials__tg}
													href="#"
													target="_blank"
													rel="nofollow noopener"
												>
													<img src="/education/reviews/Reviews-Social-Tg.svg" alt="telegram" />
												</a>
											</div>
										</div>
									</div>
									<div className={styles.reviews__block__stats}>
										<div className={styles.reviews__block__stats__column}>
											<div className={styles.reviews__block__stats__column__title}>
												До тренировок:{' '}
												<img
													src={`/education/reviews/${item.stats.before.faceit}.png`}
													alt={item.stats.before.faceit}
												/>
											</div>
											<div className={styles.reviews__block__stats__column__rows}>
												{item.stats.before.values.map((text, index) => (
													<div key={index} className={styles.reviews__block__stats__column__item}>
														{text}
													</div>
												))}
											</div>
										</div>
										<div className={styles.reviews__block__stats__column}>
											<div className={styles.reviews__block__stats__column__title}>
												После тренировок:{' '}
												<img src={`/education/reviews/${item.stats.after.faceit}.png`} alt={item.stats.after.faceit} />
											</div>
											<div className={styles.reviews__block__stats__column__rows}>
												{item.stats.after.values.map((text, index) => (
													<div key={index} className={styles.reviews__block__stats__column__item}>
														{text}
													</div>
												))}
											</div>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
				<div className={styles.reviews__buttons}>
					<button className={[styles.button__circle, 'btn-prev-reviews'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 9L12 1V17L2 9Z" fill="white" />
						</svg>
					</button>
					<button className={[styles.button__circle, 'btn-next-reviews'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 9L6 1V17L16 9Z" fill="white" />
						</svg>
					</button>
				</div>
			</section>
		</div>
	);
};

export default Reviews;
