import React, { useState, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './faq_accordion_item.module.scss';

const FaqAccordionItem = ({ title, content }) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);
	const ref = useRef();
	return (
		<div className={styles.faq_accordion_item}>
			<div
				className={styles.faq_accordion_item__header}
				onClick={() => setOpen(!open)}
			>
				<div className={styles.faq_accordion_item__title}>{title}</div>
				<button
					className={styles.button__circle}
					style={{ transform: open ? 'rotate(45deg)' : 'rotate(0)' }}
				>
					<img src="/education/Plus.svg" />
				</button>
			</div>
			<div
				className={styles.faq_accordion_item__wrapper}
				style={{ height: open ? ref?.current?.offsetHeight || 0 : 0 }}
			>
				<div ref={ref} className={styles.faq_accordion_item__content} dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</div>
	);
};

export default FaqAccordionItem;
