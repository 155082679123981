import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './who.module.scss';

const Who = () => {
	const intl = useIntl();
	return (
		<div className={styles.bg}>
			<div className={styles.container}>
				<section className={styles.who}>
					<div className={styles.who__title}>Кому подойдёт?</div>
					<div className={styles.who__row}>
						<div className={styles.who__row__item}>
							<div className={styles.who__row__item__content}>
								<div className={styles.who__row__item__content__title}>Начинающим</div>
								<div className={styles.who__row__item__content__desc}>
									Изучи настоящий Counter-Strike от А до Я с нуля. Получи необходимые навыки, чтобы начать уверенно
									побеждать и получать кайф от игры!
								</div>
							</div>
							<img src="/education/Who-1.png" alt="Начинающим" />
						</div>
						<div className={styles.who__row__item}>
							<div className={styles.who__row__item__content}>
								<div className={styles.who__row__item__content__title}>Опытным</div>
								<div className={styles.who__row__item__content__desc}>
									Закрой пробелы в знаниях, улучши коммуникацию и узнай фишки киберспортсменов, которые точно повлияют
									на твой импакт в игре.
								</div>
							</div>
							<img src="/education/Who-2.png" alt="Опытным" />
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Who;
