import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './footer.module.scss';

const socials = [
	{ id: 'VK', icon: '/education/socials/VK.svg', href: 'https://vk.com/scopegg' },
	{ id: 'Reddit', icon: '/education/socials/Reddit.svg', href: 'https://www.reddit.com/user/scopegg' },
	{ id: 'X', icon: '/education/socials/X.svg', href: 'https://twitter.com/scope_gg' },
	{ id: 'Telegram', icon: '/education/socials/Telegram.svg', href: 'https://t.me/scopegg_academy' },
];

const Footer = () => {
	const intl = useIntl();
	return (
		<footer className={styles.footer}>
			<div className={styles.footer__socials}>
				{socials.map((item) => (
					<a key={item.id} href={item.href} target="_blank" rel="nofollow">
						<img src={item.icon} alt={item.id} />
					</a>
				))}
			</div>
			<div className={styles.footer__menu}>
				{/*<div className={styles.footer__menu__list}>
					<a href="#" target="_blank" rel="nofollow">
						Политика конфиденциальности
					</a>
					<a href="#" target="_blank" rel="nofollow">
						Условия использования
					</a>
					<a href="#" target="_blank" rel="nofollow">
						Политика использования cookies
					</a>
				</div>*/}
				<div className={styles.footer__menu__copy}>© 2024 Scope. All rights reserved</div>
			</div>
		</footer>
	);
};

export default Footer;
