import React, { useEffect, useState, useRef } from 'react';
import * as styles from './trainings.module.scss';
import { setLoggedIn, setUserData } from '../../state/auth/actions';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-react-intl';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import { isBrowser } from '../../utils/ssr';
import { authService } from '../../services/authService';
import { mixPanelService } from '../../services/mixPanelService';
import { paymentService } from '../../services/paymentService';
import Footer from '../../components/molecules/trainings/Footer';
import Who from '../../components/molecules/trainings/Who';
import Promo from '../../components/molecules/trainings/Promo';
import Steps from '../../components/molecules/trainings/Steps/Steps';
import When from '../../components/molecules/trainings/When';
import FAQ from '../../components/molecules/trainings/FAQ';
import Price from '../../components/molecules/trainings/Price';
import Programm from '../../components/molecules/trainings/Programm/Programm';
import Analytics from '../../components/molecules/trainings/Analytics';
import Reviews from '../../components/molecules/trainings/Reviews';
import Trainers from '../../components/molecules/trainings/Trainers';
import Education from '../../components/molecules/trainings/Education';
import Header from '../../components/molecules/trainings/Header';
import SuccessPopup from '../../components/molecules/trainings/SuccessPopup';
import FailPopup from '../../components/molecules/trainings/FailPopup';

let timer;

const TrainingsLayout = ({ setIsLoggedIn, setUserData, isLoginPopupOpen, setLoginPopupOpen }) => {
	const intl = useIntl();
	const promoRef = useRef(null);
	const priceRef = useRef(null);
	const afterPriceRef = useRef(null);
	const [buyButtonVisible, setBuyButtonVisible] = useState(false);
	const account = useSelector((state) => state.authReducer);

	const [succesPopupOpen, setSuccessPopupOpen] = useState(false);
	const [failPopupOpen, setFailPopupOpen] = useState(false);

	//const [paymentId, setPaymentId] = useState(null);

	/*useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchFragment = window.location.search;
      const queryStringIndex = searchFragment.indexOf('payment_id');
      if (queryStringIndex !== -1) {
        const queryString = searchFragment.substring(queryStringIndex);
        const queryParams = new URLSearchParams(queryString);
        const paymentId = queryParams.get('payment_id');
				setPaymentId(paymentId);
				if (timer) {
					clearInterval(timer);
				}
      }
    }
  }, []);

	useEffect(() => {
		if (!paymentId) return
		timer = setInterval(async () => {
			try {
				const paymentStatus = await paymentService.getPaymentStatus({ paymentId });
				console.log(paymentStatus, 'paymentStatus')
			} catch (error) {
				console.error(error);
			}
		}, 10000);

		return () => clearInterval(timer);
	}, [paymentId]);*/

	useEffect(() => {
		setTimeout(() => {
			mixPanelService.sendEvent({
				event: 'Loadpage Academy Landing',
				properties: {},
			});
		}, 1500);
	}, []);


	useEffect(() => {
		checkingAuthorization();
		init();

		setTimeout(() => {
			setBuyButtonVisible(false);
		}, 10);

		const observerCallback = (entries) => {
			let isAnyEntryVisible = false;
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					isAnyEntryVisible = true;
				}
			});
			setBuyButtonVisible(!isAnyEntryVisible);
		};


		const observerOptions = {
			root: null,
			threshold: 0.1,
		};

		const observer = new IntersectionObserver(observerCallback, observerOptions);

		if (promoRef.current) observer.observe(promoRef.current);
		if (afterPriceRef.current) observer.observe(afterPriceRef.current);

		return () => {
			if (promoRef.current) observer.unobserve(promoRef.current);
			if (afterPriceRef.current) observer.unobserve(afterPriceRef.current);
		};
	}, []);

	const checkingAuthorization = async () => {
		const profile = await authService.getUserData();
	};

	const init = async () => {
		const profile = await authService.getUserData();
		if (profile.isLoggedIn) {
			setIsLoggedIn(true);
			setUserData(profile.accountData);
		} else {
			if (isBrowser) {
				window.location.href = `/404`;
			}
		}
	};

	const handleScrollToPrice = () => {
	  if (priceRef.current) {
	    const elementTop = priceRef.current.getBoundingClientRect().top + window.scrollY;
	    const offsetTop = elementTop - (window.innerHeight / 2) + (priceRef.current.offsetHeight / 2);
	    window.scrollTo({ top: offsetTop, behavior: 'smooth' });
	  }
	};

	const handlePayment = async () => {
		setSuccessPopupOpen(false);
		setFailPopupOpen(false);
		const educationPaid = localStorage.getItem('educationPaid');
		if (educationPaid) {
			setSuccessPopupOpen(true);
			return;
		}

		mixPanelService.sendEvent({
			event: 'Academy / Create Purchase Link',
			properties: { amount: 5000, currency: 'RUB', provider: 'contactpay' },
		});

		const data = await paymentService.createPayment({
			currency: 'RUB',
			failUrl: `${process.env.GATSBY_APP_PROTOCOL}://${process.env.GATSBY_APP_DOMAIN}/${intl.locale}/education`,
			successUrl: `${process.env.GATSBY_APP_PROTOCOL}://${process.env.GATSBY_APP_DOMAIN}/${intl.locale}/education`
		});

		const widget = new cp.CloudPayments();

		widget.pay('charge', data.config, {
			onSuccess: function (options) {
				setSuccessPopupOpen(true);
				localStorage.setItem('educationPaid', true);
				mixPanelService.sendEvent({
					event: 'Academy / Successful Payment',
					properties: { amount: 5000, currency: 'RUB', provider: 'contactpay' },
				});
			},
			onFail: function (options) {
				setFailPopupOpen(true);
			},
			onComplete: function (paymentResult, options) {},
		});
	}

	return (
		<div className={styles.main}>
			<div ref={promoRef}>
				<Header />
				<Promo onClick={handleScrollToPrice} />
			</div>
			<div className={styles.wrapper}>
				<Steps />
				<Who />
				<Education />
				<Programm />
				<Trainers />
				{/*<Reviews />*/}
				<Analytics />
				<div className={styles.wrapper} ref={afterPriceRef}>
					<div ref={priceRef}>
						<Price onClick={handlePayment} />
					</div>
					<FAQ />
					<When onClick={handleScrollToPrice} />
				</div>
			</div>
			<Footer />
			<div className={styles.action} style={{ opacity: buyButtonVisible ? 1 : 0 }}>
				<button
					className={styles.button}
					onClick={() => {
						mixPanelService.sendEvent({
							event: 'Academy Landing / Click Buy Scroll Button',
							properties: { location: 'fixed button' },
						});
						handleScrollToPrice();
					}}
				>
					купить сейчас со скидкой
				</button>
				<div className={styles.sale}>–31%</div>
			</div>
			<SuccessPopup
				isOpen={succesPopupOpen}
				account={account}
				onClose={() => setSuccessPopupOpen(false)}
			/>
			<FailPopup
				isOpen={failPopupOpen}
				onClick={() => {
					setFailPopupOpen(false);
					handlePayment();
				}}
				onClose={() => setFailPopupOpen(false)}
			/>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setIsLoggedIn: (payload) => dispatch(setLoggedIn(payload)),
	setUserData: (payload) => dispatch(setUserData(payload)),
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(null, mapDispatchToProps)(TrainingsLayout);
