import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './carousel_item.module.scss';

const CarouselItem = ({ title, desc, img, imgSmall, active, isNext, offset = 0 }) => {
	//console.log(active, 'active');
	const intl = useIntl();
	return (
		<div
			className={[styles.carousel_item, active ? styles.carousel_item_big : ''].join(' ')}
			style={{ right: isNext ? offset : 0 }}
		>
			<img src={img} alt={title} />
			<img className={styles.carousel_item__img_small} src={imgSmall} alt={title} />
			<div className={styles.carousel_item__content}>
				<div className={styles.carousel_item__content__title}>{title}</div>
				<div className={styles.carousel_item__content__desc}>{desc}</div>
			</div>
		</div>
	);
};

export default CarouselItem;
