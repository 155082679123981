export const programmList = [
	{
		title: 'Погружение',
		subtitle: 'Модуль 1',
		content:
			'<ul><li>Настройка и оптимизация ПК</li><li>Настройка СS2</li><li>Знакомство с тренером и группой</li><li>Распределение ролей</li><li>Голосование за карты курса</li><li>Аим и как его тренировать</li><li>Старт занятий</li></ul>',
	},
	{
		title: 'Разбор карты #1',
		subtitle: 'Модуль 2',
		content:
			'<ul><li>Экономика</li><li>Роли и позиции</li><li>Дефолт</li><li>Мид-колы</li><li>Правила и особенности карты</li><li>Гранаты</li><li>Выход на плент</li><li>Пост-плент</li><li>Ретейк</li><li>Парные и командные комбинации</li><li>Фишки и лайфхаки</li><li>Аналитика соперника</li></ul>',
	},
	{
		title: 'Разбор карты #2',
		subtitle: 'Модуль 3',
		content:
			'<ul><li>Экономика</li><li>Роли и позиции</li><li>Дефолт</li><li>Мид-колы</li><li>Правила и особенности карты</li><li>Гранаты</li><li>Выход на плент</li><li>Пост-плент</li><li>Ретейк</li><li>Парные и командные комбинации</li><li>Фишки и лайфхаки</li><li>Аналитика соперника</li></ul>',
	},
	{
		title: 'Разбор карты #3',
		subtitle: 'Модуль 4',
		content:
			'<ul><li>Экономика</li><li>Роли и позиции</li><li>Дефолт</li><li>Мид-колы</li><li>Правила и особенности карты</li><li>Гранаты</li><li>Выход на плент</li><li>Пост-плент</li><li>Ретейк</li><li>Парные и командные комбинации</li><li>Фишки и лайфхаки</li><li>Аналитика соперника</li></ul>',
	},
	{
		title: 'Практическая неделя',
		subtitle: 'Модуль 5',
		content:
			'<ul><li>Психология и мораль</li><li>Коммуникация</li><li>Заготовки на карту</li><li>Скрипты и протоколы про-команд</li><li>Разбор собственных игр</li><li>Подготовка к турниру</li><li>Детальный разбор прогресса за курс с тренером и составление плана индивидуального развития</li></ul>',
	},
	{
		title: 'Выпускной',
		subtitle: 'Модуль 6',
		content:
			'<ul><li>Вручение диплома с итогами курса</li><li>Доступ в закрытое сообщество выпускников</li><li>Твои лучшие хайлайты за весь курс</li><li>Рекомендации от тренера для дальнейшего роста</li><li>Вручение наград и памятных подарков лучшим ученикам</li></ul>',
	},
	{
		title: 'турнир',
		subtitle: 'По желанию',
		content: null,
	},
];
