import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './faq.module.scss';
import { mixPanelService } from '../../../../services/mixPanelService';
import FaqAccordionItem from '../../../atoms/trainings/FaqAccordionItem/FaqAccordionItem';
import { faqList } from '../../../../constants/trainings/faq';

const FAQ = () => {
	const intl = useIntl();
	return (
		<div className={styles.container}>
			<section className={styles.faq}>
				<div className={styles.faq__title}>Часто задаваемые вопросы</div>
				<div className={styles.faq__list}>
					{faqList.map((item, index) => (
						<FaqAccordionItem key={index} {...item} />
					))}
				</div>
				<div className={styles.faq__discord}>
					<img
						src="/education/ZigZag-Left.svg"
						className={[styles.faq__discord__zigzag, styles.faq__discord__zigzag_left].join(' ')}
					/>
					<img
						src="/education/ZigZag-Right.svg"
						className={[styles.faq__discord__zigzag, styles.faq__discord__zigzag_right].join(' ')}
					/>
					<div className={styles.faq__discord__title}>Остались вопросы? Задай их нам в&nbsp;telegram!</div>
					<a
						href="https://t.me/scopegg_academy"
						target="_blank"
						rel="nofollow"
						className={[styles.button, styles.button_fit , styles.button_tg].join(' ')}
						onClick={() => {
							mixPanelService.sendEvent({
								event: 'Academy Landing / Click Telegram Button',
								properties: { location: 'landing' },
							});
						}}
					>
						<svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M9.05093 20.6886C17.4155 16.9305 22.9838 14.4333 25.78 13.2218C33.7382 9.78511 35.4111 9.19172 36.4866 9.16699C36.7255 9.16699 37.2513 9.21644 37.6098 9.51314C37.8966 9.76038 37.9683 10.0818 38.0161 10.329C38.0639 10.5763 38.1117 11.0955 38.0639 11.4911C37.6337 16.1888 35.7696 27.5868 34.8137 32.8284C34.4074 35.0536 33.6187 35.7954 32.854 35.8695C31.1811 36.0179 29.9144 34.7322 28.3132 33.6443C25.78 31.9383 24.3699 30.8752 21.9084 29.1939C19.0645 27.2654 20.9046 26.2022 22.5298 24.4715C22.9599 24.0265 30.2968 17.1036 30.4402 16.4855C30.4641 16.4113 30.4641 16.1146 30.2968 15.9662C30.1295 15.8179 29.8905 15.8674 29.6993 15.9168C29.4365 15.9663 25.4215 18.7354 17.6066 24.1995C16.4595 25.0154 15.4319 25.411 14.4998 25.3863C13.4722 25.3616 11.5125 24.7929 10.0308 24.2984C8.23838 23.705 6.80446 23.3836 6.92396 22.3452C6.99565 21.8013 7.71261 21.2573 9.05093 20.6886Z" fill="white"/>
						</svg>
						перейти в telegram
					</a>
				</div>
			</section>
		</div>
	);
};

export default FAQ;
