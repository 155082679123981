import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './trainers.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import { trainersList } from '../../../../constants/trainings/trainers';
import TrainerItem from '../../../atoms/trainings/TrainerItem/TrainerItem';
import TrainerMockItem from '../../../atoms/trainings/TrainerMockItem/TrainerMockItem';

SwiperCore.use([Navigation]);

const Trainers = () => {
	const intl = useIntl();
	const [activeSlide, setActiveSlide] = useState(0);
	const [swiperApi, setSwiperApi] = useState({});
	const [width, setWidth] = useState();

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = (event) => {
			setWidth(event.target.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const onSlideChange = (activeIndex) => {
		setActiveSlide(activeIndex);
	};

	return (
		<div className={styles.bg}>
			<section className={styles.trainers}>
				<div className={styles.trainers__title}>Наши тренеры</div>
				<div className={styles.trainers__desc}>
					Никакой магии — только проверенная методика занятий с тренером. Подберём тебе лучшего наставника, исходя из
					твоих целей и навыков.
				</div>
				<div className={styles.trainers__wrapper}>
					<div className={styles.trainers__wrapper__content}>
						<div className={styles.trainers__wrapper__content__slider}>
							<Swiper
								slidesPerView={3}
								loop={true}
								spaceBetween={0}
								centeredSlides={true}
								className="slides"
								navigation={{ nextEl: '.btn-next-trainers', prevEl: '.btn-prev-trainers' }}
								onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
								onSwiper={(swiper) => setSwiperApi(swiper)}
							>
								{trainersList.map((item, index) => (
									<SwiperSlide key={index} className="slide">
										{({ isActive, isNext }) => (
											<>
												{isActive ? (
													<TrainerMockItem active={true} {...item} offset={width < 1024 ? 0 : '-46px'} />
												) : isNext ? (
													<TrainerMockItem isNext={true} {...item} offset={width < 1024 ? 0 : '-46px'} />
												) : (
													<TrainerMockItem {...item} offset={width < 1024 ? 0 : '-46px'} />
												)}
											</>
										)}
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</div>
				<div className={styles.trainers__buttons}>
					<button className={[styles.button__circle, 'btn-prev-trainers'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 9L12 1V17L2 9Z" fill="white" />
						</svg>
					</button>
					<button className={[styles.button__circle, 'btn-next-trainers'].join(' ')}>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 9L6 1V17L16 9Z" fill="white" />
						</svg>
					</button>
				</div>
			</section>
		</div>
	);
};

export default Trainers;
