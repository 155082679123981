export const faqList = [
	{
		title: 'Подойдёт ли мне тренировка, если я новичок?',
		content:
			'Конечно! Наши опытные тренеры имеют опыт работы с разными учениками — от тех, кто только начал свой путь, до тех, кто выступает на крупных турнирах.<br /><br />Наша программа рассчитана на все возрастные категории! У многих наших тренеров есть опыт работы с детьми от 8–10 лет и старше. Родители могут задать все интересующие вопросы в чате  поддержки, либо мы можем связаться с вами лично.',
	},
	{
		title: 'Что мне нужно, чтобы начать тренироваться?',
		content:
			'Компьютер, поддерживающий игру или доступ в компьютерный клуб, хорошее интернет соединение, свободное время и, конечно же, желание становиться лучше! Без всего этого вырасти не получится!',
	},
	{
		title: 'Где будет происходить общение с тренером?',
		content:
			'Все тренировки будут проходить в Team Speak, а анонсы и чат с тренером будут в Telegram.',
	},
	{
		title: 'Как проходит тренировка?',
		content: 'Тренировка длится около 3 часов, 1 час - теория, 2 часа практики и небольшой перерыв.<br />Ученики вместе с тренером заходят в голосовой канал и на сервер в CS2, где тренер рассказывает теорию об игре на карте: все необходимое, что поможет понять карту на новом уровне и играть на ней в командный CS.<br/>После разбора вас ждет 2 прака, каждый из которых будет проводиться под присмотром тренера.',
	},
	{
		title: 'Как я могу оплатить курс?',
		content: 'Нажимай на кнопку \"Купить\" и следуй инструкциям платежной системы!',
	},
	{
		title: 'Сколько длится курс?',
		content: '4 недели или 1 календарный месяц.',
	},
	{
		title: 'Смогу ли я совмещать курс с работой/учебой?',
		content: 'Конечно! Мы подстроимся под тебя и твои жизненные обстоятельства насколько это возможно.',
	},
	{
		title: 'Как стать тренером?',
		content:
			'Всё просто! Напиши нам в поддержку в чате Telegram по кнопке ниже или отправь нам письмо на почту <a href="mailto:academy@scope.gg">academy@scope.gg</a> с темой \"Хочу быть тренером Scope Academy!\". Наши менеджеры свяжутся с тобой и расскажут об условиях и правилах отбора.',
	},
	{
		title: 'Оплатил курс. Что будет дальше?',
		content: 'После оплаты тебе нужно заполнить анкету в нашем TG-боте. Ссылку на него ты получишь сразу после успешной оплаты. Далее остается ждать информации от менеджера, который свяжется с тобой в течение 48 часов!',
	},
	{
		title: 'Опаздываю на тренировку/не смогу присутствовать, что делать?',
		content:
			'У нас есть правила проведения тренировок. Все организационные вопросы по тренировкам решаются через чат с вашим тренером в Telegram.',
	},
	{
		title: 'Могу ли я поменять тренера, если текущий мне не подходит?',
		content:
			'Конечно! Ты можешь поменять тренера в любой момент до начала курса, наша команда поддержки на связи 24/7 и поможет определиться с выбором наиболее подходящего тренера под твою задачу. У большинства тренеров есть своя специализация (карты в CS2, роли, стили), так что мы будем стараться подобрать тебе занятия только с теми тренерами, которые помогут тебе достичь твоей цели. Во время курса замена тренера должна согласовываться с менеджером, так что заявка займет какое-то время.',
	},
	{
		title: 'Могу ли я запросить возврат средств?',
		content:
			'Ты можешь запросить возврат средств до начала курса, либо в первые 7 дней с момента старта курса. За каждый день курса у тебя будет произведен вычет из общей суммы при расчёте возврата. Если у тебя ещё остались какие-то вопросы — смело пиши нам в поддержку!',
	},
];
