import React, { useState, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './programm_accordion_item.module.scss';

const ProgrammAccordionItem = ({ title, subtitle, content, blockId }) => {
	const intl = useIntl();
	const [open, setOpen] = useState(false);
	const ref = useRef();
	return (
		<div className={styles.programm_accordion_item}>
			<div
				className={styles.programm_accordion_item__header}
				onClick={() => {
					if (!open) {
						mixPanelService.sendEvent({
							event: 'Academy Landing / Click Expand Program Button',
							properties: { block: blockId },
						});
					}
					setOpen(!open);
				}}
			>
				<div className={styles.programm_accordion_item__column}>
					<div className={styles.programm_accordion_item__subtitle}>{subtitle}</div>
					<div className={styles.programm_accordion_item__title}>{title}</div>
				</div>
				{content ? (
					<button
						className={styles.button__circle}
						style={{ transform: open ? 'rotate(45deg)' : 'rotate(0)' }}
					>
						<img src="/education/Plus.svg" />
					</button>
				) : null}
			</div>
			<div
				className={styles.programm_accordion_item__wrapper}
				style={{ height: open ? ref?.current?.offsetHeight || 0 : 0 }}
			>
				<div
					ref={ref}
					className={styles.programm_accordion_item__content}
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</div>
		</div>
	);
};

export default ProgrammAccordionItem;
